import React from "react"
import settingsStyle from "../../components/hacker-news/edit-settings.module.css"

export interface Props {
  index: string
  text: string
  settings: string
}

export default ({ index, text, settings }) => {
  return (
    <div className={settingsStyle.formGroupSelect}>
      <label className={settingsStyle.labelControl} htmlFor={index}>
        {text}
      </label>
      <div className={settingsStyle.formControl}>
        <select id={index}>
          {settings.lists[index].map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
