import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CopyrightInfo from "../../components/copyright-info"
import SiteLinks from "../../components/site-links"

import HNPage from "../../components/hacker-news/hn-page"
import LogoHN from "../../components/hacker-news/logo-hn"
import NavigationLinks from "../../components/hacker-news/navigation-links"
import PageHeader from "../../components/hacker-news/page-header"
import { Separator } from "../../components/gatsby-sidebar/docs-breadcrumb"
import Sidebar from "../../components/hacker-news/sidebar"
import SidebarItem from "../../components/hacker-news/sidebar-item"

import settingsStyle from "../../components/hacker-news/edit-settings.module.css"
import FormGroupSelect from "../../components/hacker-news/form-group-select"
import { data } from "../../components/hacker-news/data-for-settings"

import {
  Eye,
  HelpCircle,
  Home,
  TrendingUp,
  MessageCircle,
  Star,
  Volume2,
} from "react-feather"

import sidebarStyles from "../../components/hacker-news/sidebar.module.css"

function FormGroupCheckbox({ index, text, settings }) {
  return (
    <div className={settingsStyle.formGroupCheckbox}>
      <label className={settingsStyle.labelControl} htmlFor={index}>
        {text}
      </label>

      <input
        className={settingsStyle.formControl}
        id={index}
        value={settings.storyText}
        type="checkbox"
      />
    </div>
  )
}

const Settings = () => {
  const [showThumbnails, setShowThumbnails] = useState(false)
  const [siteStyle, setSiteStyle] = useState("default")
  const [dateRange, setDateRange] = useState("all")
  const [page, setPage] = useState(30)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [hasSideBar, setHasSideBar] = useState<boolean>(true)

  return (
    <Layout>
      <SEO title="Search Hacker News Project" />

      <HNPage>
        <PageHeader>
          <LogoHN />
        </PageHeader>

        <div
          id="main"
          role="presentation"
          style={{
            width: `100%`,
            border: `red solid 1px`,
            display: `flex`,
          }}
        >
          <div
            id="content"
            role="presentation"
            style={{
              marginLeft: `230px`,
              border: `red solid 1px`,
              width: `100%`,
            }}
          >
            <section className={settingsStyle.base}>
              <h2>Display options</h2>
              <fieldset>
                {/* <!-- ngIf: settings.style === "experimental" --> */}
                <FormGroupSelect
                  text={"Style"}
                  index={"style"}
                  settings={data.settings}
                />

                <FormGroupSelect
                  text={"Hits per page"}
                  index={"hitsPerPage"}
                  settings={data.settings}
                />
              </fieldset>

              <h2>Ranking options</h2>
              <fieldset>
                <FormGroupSelect
                  text={"Default type"}
                  index={"defaultType"}
                  settings={data.settings}
                />

                <FormGroupSelect
                  text={"Default sort order"}
                  index={"defaultSortOrder"}
                  settings={data.settings}
                />

                <FormGroupSelect
                  text={"Default date range"}
                  index={"defaultDateRange"}
                  settings={data.settings}
                />

                <FormGroupCheckbox
                  text={"Use the story text for the search"}
                  index={"storyText"}
                  settings={data.settings}
                />

                <FormGroupCheckbox
                  text={"Use the author's username for the search"}
                  index={"authorText"}
                  settings={data.settings}
                />

                <FormGroupCheckbox
                  text={"Typo-tolerance"}
                  index={"typoTolerance"}
                  settings={data.settings}
                />
              </fieldset>
            </section>

            <NavigationLinks>Navigation links</NavigationLinks>
          </div>

          <Sidebar siteStyle={siteStyle}>
            <ul className={sidebarStyles.base}>
              <SidebarItem linkTo="/hacker-news/" text="All" icon={<Home />} />
              <SidebarItem
                linkTo="/hacker-news/hot/"
                text="Hot"
                icon={<TrendingUp />}
              />
              <SidebarItem
                linkTo="/hacker-news/show-hn/"
                text="Show HN"
                icon={<Volume2 />}
              />
              <SidebarItem
                linkTo="/hacker-news/ask-hn/"
                text="Ask HN"
                icon={<MessageCircle />}
              />
              <SidebarItem
                linkTo="/hacker-news/polls/"
                text="Polls"
                icon={<HelpCircle />}
              />
              <SidebarItem
                linkTo="/hacker-news/jobs/"
                text="Jobs"
                icon={<Eye />}
              />
              <hr />
              <SidebarItem
                linkTo="/hacker-news/starred/"
                text="Starred"
                icon={<Star />}
              />
              <hr />
            </ul>

            {/*<div className="sliding-menu-fade-screen ng-scope" ng-click="toggleNav()"></div>*/}
          </Sidebar>
        </div>
      </HNPage>

      <SiteLinks>
        <Link to="/">Landing Page</Link>{" "}
        <Separator character={<span>{` `}</span>} />
        <Link to="/index-project/">Projects</Link>
      </SiteLinks>

      <CopyrightInfo />
    </Layout>
  )
}

export default Settings
