export const data = {
  settings: {
    ALGOLIA_SETTINGS: {
      authorText: true,
      dateRange: "all",
      defaultSort: "byPopularity",
      defaultType: "story",
      hitsPerPage: 30,
      page: 0,
      prefix: false,
      showThumbnails: false,
      sort: "byPopularity",
      storyText: true,
      style: "default",
      theme: "dark",
      type: "story",
      typoTolerance: true,
    },
    ALGOLIA_SESSION: {
      login: false,
    },
    state: "",
    lists: {
      searchWhat: [
        {
          text: "All",
          link: "/",
          iconClassName: "icon-news",
          state: "",
        },
        {
          text: "Stories",
          link: "",
          iconClassName: "",
          state: "",
        },
        {
          text: "Comments",
          link: "",
          iconClassName: "",
          state: "",
        },
      ],
      searchBy: [
        {
          text: "Popularity",
          link: "",
          iconClassName: "",
          state: "",
        },
        {
          text: "Date",
          link: "",
          iconClassName: "",
          state: "",
        },
      ],
      searchTimePeriod: [
        {
          text: "All Time",
          link: "",
          iconClassName: "",
          state: "",
        },
        {
          text: "Last 24h",
          link: "",
          iconClassName: "",
          state: "",
        },
        {
          text: "Past Week",
          link: "",
          iconClassName: "",
          state: "",
        },
        {
          text: "Past Month",
          link: "",
          iconClassName: "",
          state: "",
        },
        {
          text: "Past Year",
          link: "",
          iconClassName: "",
          state: "",
        },
        {
          text: "Custom Range",
          link: "",
          iconClassName: "",
          state: "",
        },
      ],
      shareHow: [
        {
          text: "Share on Twitter",
          link: "",
          iconClassName: "",
          state: "",
        },
        {
          text: "Share on Facebook",
          link: "",
          iconClassName: "",
          state: "",
        },
        {
          text: "Share by Email",
          link: "",
          iconClassName: "",
          state: "",
        },
      ],
      style: ["Default", "Experimental"],
      hitsPerPage: ["10", "20", "30", "50"],
      defaultType: ["All", "Stories", "Comments"],
      defaultSortOrder: ["Most Recent First", "Most Popular First"],
      defaultDateRange: [
        "Last 24h",
        "Past Week",
        "Past Month",
        "Past Year",
        "Forever",
      ],
      storyText: true,
      authorText: true,
      typoTolerance: true,
      routes: {
        home: {
          text: "All",
          link: "/",
          iconClassName: "icon-news",
          state: "",
        },
        about: {
          text: "About",
          link: "/about/",
          iconClassName: "",
          state: "",
        },
        settings: {
          text: "Settings",
          link: "/settings/",
          iconClassName: "",
          state: "",
        },
        help: {
          text: "Help",
          link: "/help/",
          iconClassName: "",
          state: "",
        },
        api: {
          text: "API",
          link: "/api/",
          iconClassName: "",
          state: "",
        },
        coolApps: {
          text: "Cool Apps",
          link: "/cool-apps/",
          iconClassName: "",
          state: "",
        },
        hot: {
          text: "Hot",
          link: "/hot",
          iconClassName: "icon-fire",
          state: "",
        },
        showHN: {
          text: "Show HN",
          link: "/show-hn",
          iconClassName: "icon-megaphone",
          state: "",
        },
        askHN: {
          text: "Ask HN",
          link: "/ask-hn",
          iconClassName: "icon-bubble",
        },
        polls: {
          text: "Polls",
          link: "/polls",
          iconClassName: "icon-bulb",
          state: "",
        },
        jobs: {
          text: "Jobs",
          link: "/jobs",
          iconClassName: "icon-banknote",
          state: "",
        },
        starred: {
          text: "Starred",
          link: "/starred",
          iconClassName: "icon-star",
          state: "",
        },
      },
    },
  },
}
